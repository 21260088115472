import React, { useEffect, useState } from "react"
import { BlogContainer, SubTitleSection, Text, Tag } from "../../atomics"
import { useStaticQuery, Link } from "gatsby"
import { format, parseISO, isAfter, compareAsc } from "date-fns"
import Page from "../Page"
import { graphql } from "gatsby"
import { GiStopwatch } from "react-icons/gi"
import { calcReadingTime } from "../../../utils/string"

const query = graphql`
  {
    allStrapiPosts(filter: {}, sort: { order: DESC, fields: id }) {
      nodes {
        author
        content
        created_at
        date
        description
        id
        title
        visible
        banner {
          publicURL
        }
        keywords
        url
        categories {
          id
          label
        }
      }
    }
    allStrapiCategories {
      nodes {
        label
        strapiId
      }
    }
  }
`

const Blog = () => {
  const {
    allStrapiPosts: { nodes },
    allStrapiCategories: { nodes: categories },
  } = useStaticQuery(query)
  const [posts, setPosts] = useState(nodes)
  const offset = 10
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState(null)
  const description =
    "Os melhores contéudos sobre produtividade, hábitos, metas e gestão de tarefas e tempo para você colocar em prática, ser mais produtivo e atingir seus objetivos!"
  const [search, setSearch] = useState("")

  useEffect(() => {
    let postsVisibles = [
      ...nodes
        .filter(post => post.visible)
        .sort((a, b) => compareAsc(parseISO(b.date), parseISO(a.date))),
    ]
    if (filters) {
      postsVisibles = postsVisibles.filter(
        post =>
          post.visible &&
          post.categories.find(category => filters === category.id)
      )
    }
    if (search) {
      postsVisibles = postsVisibles.filter(
        post =>
          post.title.toLocaleUpperCase().includes(search.toLocaleUpperCase()) ||
          post.description
            .toLocaleUpperCase()
            .includes(search.toLocaleUpperCase())
      )
    }
    setPosts(postsVisibles)
  }, [filters, nodes, search])

  return (
    <Page title={"Blog Flynow"} description={description} pageId="blog">
      <BlogContainer>
        <Text className="text-description">{description}</Text>
        <div className="border" />
        <SubTitleSection className="title-blog">Assuntos:</SubTitleSection>
        <div className="tags">
          <Tag active={!filters} onClick={() => setFilters(null)}>
            Todos
          </Tag>
          {categories.map(category => (
            <Tag
              key={category.strapiId}
              onClick={() => {
                setFilters(category.strapiId)
                setPage(1)
              }}
              active={category.strapiId === filters}
            >
              {category.label}
            </Tag>
          ))}
        </div>

        <input
          className="input-search"
          placeholder="Pesquisar por palavra chave..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />

        <div className="border" />

        <div className="posts">
          {posts
            .filter(post => isAfter(new Date(), parseISO(post.date)))
            .splice(0, page * offset)
            .map(post => (
              <Link to={post.url} className="post" key={post.id}>
                <SubTitleSection className="title-blog">
                  {post.title}
                </SubTitleSection>
                {post.banner && (
                  <img
                    src={post.banner.publicURL}
                    style={{
                      width: 380,
                      height: 200,
                      marginTop: 5,
                      objectFit: "cover",
                    }}
                    alt={post.title}
                  />
                )}
                <Text
                  style={{ marginTop: 5 }}
                  className="text-description shadow"
                >
                  {post.description}
                </Text>
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Roboto",
                    marginTop: 6,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                <div className="text-date"  style={{
                    display: "flex",
                    fontFamily: "Roboto",
                    marginTop: 6,
                    alignItems: "center",
                  }}>
                    <GiStopwatch size={20} />
                  <h6  style={{ margin: 2, marginTop: 2 }}>
                    {calcReadingTime(post.content)} min
                  </h6>
                </div>
                  <h6 className="text-date" style={{ marginTop: 5 }}>
                    {format(parseISO(post.date), "dd/MM/yyyy")}
                  </h6>
                </div>
              </Link>
            ))}
        </div>
        {posts.length > page * offset && (
          <button className="see-more" onClick={() => setPage(p => p + 1)}>
            Ver mais
          </button>
        )}
      </BlogContainer>
      <br />
      <br />
    </Page>
  )
}

export default Blog
