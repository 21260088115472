exports.stringToSlug=(str)=> {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = "àáäâãèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes
  return str;
}

function markdownToText(markdown) {
  // Remove código, cabeçalhos, listas, links e imagens
  let plainText = markdown
  .replace(/`{3}[\s\S]*?`{3}/g, '') // Remove blocos de código
  .replace(/`{1}[^`]*`{1}/g, '') // Remove código inline
  .replace(/^#{1,6}\s/gm, '') // Remove cabeçalhos
  .replace(/[\*\-_]{3,}/g, '') // Remove linhas horizontais
  .replace(/!\[.*?\]\(.*?\)/g, '') // Remove imagens
  .replace(/\[([^\]]+)\]\(.*?\)/g, '$1') // Remove links, mantendo o texto do link
  .replace(/^\s*[\*\+\-]\s/gm, '') // Remove listas não ordenadas
  .replace(/^\s*\d+\.\s/gm, '') // Remove listas ordenadas
  .replace(/>\s/gm, '') // Remove blockquotes
  .replace(/<[^>]*>/g, '') // Remove tags HTML
  .replace(/\s{2,}/g, ' ') // Substitui múltiplos espaços por um único espaço
  .trim();
  
  return plainText;
}
exports.markdownToText = markdownToText

exports.calcReadingTime=(markdown)=> {
  const plainText = markdownToText(markdown);
  const words = plainText.split(/\s+/).filter(word => word.length > 0).length;
  const wordsPerMinute = 250;
  const minutes = words / wordsPerMinute;
  return Math.ceil(minutes); // Arredonda para cima para garantir que a leitura completa seja coberta
}
